
import Vue from 'vue';
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      heatMapMode: 'actualValue',
      lastTimeStamp: Date.now()
    };
  }, // data
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingCompared',
      'getStartingCompared',
      'getEndingPeriod'
    ]),

    ...mapState('traffic', [
      'entranceMode',
      'entranceAverage',
      'powerHourMode',
      'entranceInout'
    ])
  },
   async mounted() {
    Vue.prototype?.$mixpanel?.track('traffic_report', {
      referrer: document.referrer
    });
    // alert('test2');
    // await this.fetchAllTrafficFidaOnChange();
},

  methods: {
     ...mapActions('traffic',['fetchAllTrafficFida','fetchAllTrafficFidaV2']
    ),
    ...mapMutations('traffic', [
      'setEntranceMode',
      'setEntranceAverage',
      'setPowerHourMode',
      'setentranceInout'
    ]),

    setHeatMapMode(newValue) {
      this.heatMapMode = newValue;
    },
    async refreshPage() {

      this.lastTimeStamp = Date.now();
        await this.fetchAllTrafficFidaOnChange();
    }, // refreshPage
    async fetchAllTrafficFidaOnChange() {
      try {
        const response = await this.fetchAllTrafficFidaV2({//fetchAllTrafficFidaV2
        startDate: this.getStartingPeriod,
        endDate: this.getEndingPeriod,
        daysOfWeek: this.getDaysOfWeekList,
        storeCodes: this.getStoreCodesList,
        storeCodeList: this.getStoresCodeName
      });
    } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
  } // methods
}; // export default
